.button-group {
	display: flex;
	justify-content: space-between;
	margin-top: 24px;
}

.button {
	padding: 12px 20px;
	border-radius: 4px;
	font-size: 16px;
	cursor: pointer;
	border: none;
}

.keep-subscribed {
	background-color: #eee;
	color: #333;
	padding-right: 16px;
	position: relative;
	right: -503px;
	border-style: solid;
	border-color: #6200ee;
}

.keep-subscribed:hover {
	background-color: #ddd;
}

.unsubscribe {
	background-color: #6200ee;
	/* Purple button */
	color: white;
}

.unsubscribe:hover {
	background-color: #5400cc;
}