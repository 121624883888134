.ReactModal__Body--open {
	position: static;
	/* Firefox IE fix */
	max-height: 100%;
}

/* Fade in */
.ReactModal__Content {
	opacity: 0.1;
}
.ReactModal__Content--after-open {
	opacity: 1;
	transition: opacity 250ms ease-out;
}
