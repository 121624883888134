.red5pro-media-container {
  display: block;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  line-height: 0px;
  width: 100% !important;
}

.red5pro-media {
	width: 100% !important;
	height: 100%;
  }

.red5pro-media-portrait {
	width: 100% !important;
	height: 100%;
	max-width: 640px;
}

.red5pro-media-background {
  background-color: #000;
}

.red5pro-media-container-full-screen {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
  max-width: none;
}

.red5pro-media-container-full-screen-portrait {
	width: 100% !important;
	height: 100% !important;
	overflow: hidden !important;
	max-width: 100vh !important;
}

.red5pro-media-control-bar {
  font-family: sans-serif;
  opacity: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.5em;
  padding: 0.5em;
  background-color: rgba(255, 255, 255, 0.9);
  color: #595959;
  -webkit-transition: opacity 0.5s ease-in-out;
     -moz-transition: opacity 0.5s ease-in-out;
      -ms-transition: opacity 0.5s ease-in-out;
       -o-transition: opacity 0.5s ease-in-out;
          transition: opacity 0.5s ease-in-out;
}

input[type=range][orient=vertical] {
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    width: 8px;
    height: 175px;
    padding: 0 5px;
}

.red5pro-media-control-bar-show {
  opacity: 1;
}

.red5pro-media-control-element {
  margin: 0 0.5em;
  cursor: pointer;
}
.red5pro-media-time-field {
  font-size: 0.8em;
  line-height: 1em;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.red5pro-media-slider {
  -webkit-appearance: none !important; /*Needed to reset default slider styles */
  transition: all 0.3s ease;
  margin-top: 0.8em;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.red5pro-media-slider-track {
  background-color: #595959;
  height: 0.2em;
  position: relative;
  display: block;
  top: 0.1em
}

.red5pro-media-slider-progress {
  background-color: #4286F4;
  height: 0.2em;
  position: relative;
  display: block;
  top: -0.1em;
}

.red5pro-media-slider-button {
  width: 1em;
  height: 1em;
  max-width: 24px;
  max-height: 24px;
  top: -0.7em;
  position: relative;
  display: block;
  border-radius: 20px;
  background-color: #4286F4;
}

.red5pro-media-slider-disabled {
  background-color: #bbb;
  cursor: arrow;
}

.red5pro-media-volume-slider {
  width: 110px;
}

.red5pro-media-seektime-slider {
  flex-grow: 1;
  -ms-flex-grow: 1;
  -ms-flex-positive: 1;
}

.red5pro-media-element-button {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}

.red5pro-media-element-button-disabled {
  cursor: arrow;
  opacity: 0.5;
}

.red5pro-media-play-button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4QcGDR8MSrMwrQAAAS1JREFUSMftlbFKA0EQhv85Vw+xsBBfQBCsBAtLO19hOW7kUil2EWwtPN9ABV9AZXJwbyAWVwSbgEWw8BBsY5PSxiw3VtcZvcRsl69chv8r/hkWmDMNcRzfW2vXZ5EVjHk/MMa8MfNJmqaBDwEArKrqZVmWRRzHWz4ENXsAnpk5tdYu+RAAwLKqnhtjelEU7foQ1GwHQfDEzFdJkqz4EACAUdW2c67PzPs+BDUbqvrAzLetVmvNhwAASFWT0Wj0wszWh6BmoaqqRR+CioiunXObWZbJj4X9I/wVwJGIdGdd8hcRXYRhuNPpdLp/rtwkyaraI6JDEek33umGc59EdDYYDG6KonATHU2DmUciOhaR92mK+k0wJKJTEbkDoNNuwjhB7pxr53n+Mf9+vfMNOh1lhqBAXiIAAAAASUVORK5CYII=);
}

.red5pro-media-pause-button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4QcGDR4xC8BN/QAAADJJREFUSMdjYBgFAw0YcUlERkb+R+YvX76ckRR5GGCitQ9GLRi1YNSCUQtGLRgFwwMAAKnJCCB7toq/AAAAAElFTkSuQmCC);
}

.red5pro-media-unmute-button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4QcGDR8n5g/J7QAAAkRJREFUSMftVTGIE0EUfX93IyQSIRbigaCgpTYnXCF4nIUcKIIga3YmWRshlZ2KyjWp5CyuOxAOjGAxmxgUCzWcCN5pIQTORkUiCDZaHoE1RbKb+Ta7R4ibS+RALO7Dwufvn//+m/dnBti1f2W2bZulUik1HDcmWZzP549KKTeEEOujcizLeuX7/lfbtg/+FYCUct4wjCYzTwOYHZXHzD6AI5ZlPbFte89YgHK5bDiOs8jMDQD7xzWSyWRcAJ8BnLIs6+a2AK7r7m21WlUiugVAE9HtBGY5IcSdYrE4BQCVSsVn5mvR7+tSytwWgBDirRCC4y8Mw18AbACbRHReKXUvYUsuA7irtX4eb0m1Wl0DsAYgx8wXBxmcTiCyobWeUUqtJrFMp9MKwBdmnk6lUlfjOBE9jtxzAGANLvI8jyYdy0ql4juOs0BET7XWVwDcjwDWmRkAjk88ptsI+zoqeiKO9Xq9H5E7tWOAbrcbM+Y4ls1m9eAA7QhAa302EvxTHOt0Ooci9+cfGggheEjkfK1W+5ZUvFAo7Ov3+4tEBMMwHsVx0zTnIg0+DjJ4l1DjpGEYTSnl/IjuBREdI6IPQRA8GBpfAHi5xcDzvNnhgxaG4UMANjO/kFIuRF1hcBy11hnTNKv1er0XHb4zzDwHoE1EzwCAxtxDJWZeBpAaN8qu6x4Iw7AJ4DCAG57nLY0VWSm1QkQXAGyOEzwIgpWo+Pt2u7088W2qlFrVWs8AaAJ4MyqPiLIAvpumeanRaHR3X8j/x34DRnnn0LwuMegAAAAASUVORK5CYII=);
}

.red5pro-media-mute-button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4QcGDR84awfEGAAAAb9JREFUSMftVUFrE0EU/r7JCEPtSfDSYy3ePIneiodAIojHJbsLufoTVPCSk/QHePLinnaNC3oTKpRAPVhSLAUvycFfECg9SFjIZD8PJqAlaZpYEKEfDAyP9+ab9973ZoAr/GvwIk6NRuNWpVJ5J+lHlmUPliGwixziOK5LSiXdWCWDuQStVsv0er2Xkp5eNNNZMLOMzWbzer/ff0vyGYCS5PNVCSwARFG0D2B7avTeT7cnJOM0TXejKNqZdUAURfuS3Hg8fpTn+QAAgiC4aa39CGA4LdH2jNivZVk22u329/NuKMmRvGet3QuCoAoA1to9AHcAdP/oQZZlS9faOVcriuLThORgYt6UdOicq5u/1XmSJKfOuRqAYwCbk3XsnKslSXJqLmOYiqJYI7ku6ddwketFUazNVdEyCMNwg2RH0pYx5kjSkaQtkp0wDDfsGUVo2SaT/ADgNoBvo9Ho4e9NJvl+msHnGbF3jTHdOI7rC5IYAvjiva/meT7I83zgva9KOgAw5LxB896/ARAAGJN8IWlnFaVxwTv0RNIrANdWlfK5TU7T9DXJxwBOLvUtOkOyW5blfQBdAJ2rH+7/w08RWLPQvnuidAAAAABJRU5ErkJggg==);
}

.red5pro-media-fullscreen-button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4QcGCxw4RKfraQAAAO5JREFUSMftlTFKBEEQRd/fHgw7MxCDvYgoeIcBa0AED6GgIIveYmARA2dOoYGexEgxnFSGMpmWdV1hndVgZX7Sn6ru/7u66S5YdyiRPM83QgiXkg6A8eykuq61aLGZ+Vzoyd1vY4yTsizfALKUybLsCjhZccNjSWdN0wCcfzIADgEk7VVV9bCM2nxlZrYP3Ek6SgajmfwWwLLi3xjed3Q7xUZ/fcnrbzBgwC/CzHzB57WyzvCSf2Tw0p3hTl+xoih2O/r80WcScfcbSafAo5nRp6O5exqvvxi0bXsRQkDSMbDZs4hXYBpjnPyf9/UOCvNEEaDWmeQAAAAASUVORK5CYII=);
}

.red5pro-media-exit-fullscreen-button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4QcGDR4UQMSZugAAAORJREFUSMftlTFKA1EQhr/xPdLtdjamyEVExDtsMQsi5BAREYl6jEUIaTKniBA8SaoQ0m2bhWfzhI3sLkRIE/dvZuD/h3+GYd6Ds4aqBlUNf+UBLk7d5Bkb5Hl+E9NNR/027uK6TeDrC6sTIYSfOOswmAMT4EtVDwgzkwODBuyAjzRNX9sEVVW9OOeCiIyBS3r0OAmk7Q6ANbBIkmRaFMW+qTjLsoH3/g1QYHTsHYyAp7IsA/DcJHDOvQOPXRP43461ie6ApYg8tBmIyH1Mb81sddRbZGafMR12NHgVtav+P/jH+AYb80AK5v+i6QAAAABJRU5ErkJggg==);
}

